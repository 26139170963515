import React from "react";
import "./style.scss";

import { Container, Card, Row, Col } from "react-bootstrap";

import {
  KeyboardBackspaceRounded,
  CallRounded,
  ForumRounded,
  CloseRounded,
} from "@mui/icons-material";

function index() {
  return (
    <Container fluid className="main-container">
      <div xxl={2} xl={2} md={2} xs={2} sm={2} className="card-container">
        <div className="card-body">
          <div className="card-navigation">
            <button className="navigation-btn">
              <KeyboardBackspaceRounded className="navigation-icon" />
            </button>
          </div>
          <div className="card-header">
            <div className="inner">
              <div className="left-header">
                <h2 className="header-text lato-black">New Invites</h2>
                <span className="header-date lato-regular">Jan 12, 2025</span>
              </div>
              <div className="right-header">
                <div className="profile">
                  <div className="profile-inner"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body-section">
            {/* main card */}
            <div className="body-container">
              <div className="body-top">
                <div className="body-header">
                  <div className="header-top">
                    <span className="header-rule lato-regular">
                      Wedding Invitation
                    </span>
                    <div className="header-callout">
                      <button className="header-btn">
                        <CallRounded className="callout-icon" />
                      </button>
                      <button className="header-btn">
                        <ForumRounded className="callout-icon" />
                      </button>
                    </div>
                  </div>
                  <div className="header-bottom">
                    <h4 className="main-header lato-bold">
                      Jessica and Christopher wedding reception
                    </h4>
                  </div>
                </div>
                <div className="body-details">{/* <TicketTransfer /> */}</div>
              </div>
              <div className="body-footer">
                <div className="footer-top">
                  <span className="footer-top-text lato-regular">
                    BID:2547-2878-2876-9878
                  </span>
                </div>
                <div className="footer-bottom">
                  <span className="footer-bottom-text lato-regular">
                    RSVP: Nkuryo Christopher by 6 Nov 2024
                  </span>
                </div>
              </div>
            </div>
            {/* main card */}
            <div className="body-3d-shadow-1"></div>
            <div className="body-3d-shadow-2"></div>
          </div>
        </div>
        <div className="card-footer">
          <div className="footer-inner">
            <button className="cancel-btn">
              <CloseRounded className="cancel-icon" />
            </button>
            <button className="accept-btn lato-regular">Accept Transfer</button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default index;
